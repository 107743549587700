
























































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { User, Environment, EnvironmentUser, View, Link } from '@/models'
import openLink from '@/utils/route/openLink'
import StatusBar from '@/components/mobileApp/StatusBar.vue'
import gql from 'graphql-tag'
import _snakeCase from 'lodash/snakeCase'

@Component({
  components: {
    StatusBar
  },
  filters: {
    fixLegacyIcons (input : string) {
      if (input && input.indexOf('Md') === 0) {
        return _snakeCase(input.slice(2))
      }
      return input
    }
  },
  apollo: {
    userMenu: {
      query: gql`query ($environmentId : ID) {
        userMenu (environmentId: $environmentId)
      }`,
      variables () {
        return {
          environmentId: this.environment._id
        }
      }
    }
  }
})
export default class EnvironmentProfileNavbar extends Vue {
  @Prop({ type: Object, required: true }) environment !: Environment
  @Prop({ type: Object, required: true }) environmentUser !: EnvironmentUser
  @Prop({ type: String }) title ?: string
  @Prop({ type: String }) path ?: string
  @Prop({ type: Boolean, default: false }) sidebar !: boolean
  @Prop({ type: Boolean, default: false }) sidebarOpen !: boolean
  @Prop({ type: [Number, String] }) notificationCount ?: number | string

  userMenu : Link[] = []

  mounted () {
    this.updateTitle()
  }

  async logout () {
    await this.$store.dispatch('auth/logout')
    await this.$router.push('/login')
    return
  }

  toggleDarkMode () {
    if (!this.environment.allowDarkMode) return
    this.$store.commit('app/toggleDarkMode')
  }

  toggleEditLinks () {
    if (!this.isAdmin) return
    this.$store.commit('app/toggleEditLinks')
  }

  get envProfile () {
    return this.environmentUser.profile
  }

  get user () : User {
    return this.$store.state.auth.user
  }

  get userAvatar () : string {
    return this.$store.getters['auth/userAvatarURL']
  }

  get userRoleDisplay () : string {
    if (!this.user) return ''
    if (this.user.roles.includes('superAdmin')) {
      return 'Súper Administrador'
    }
    if (this.user.roles.includes('admin')) {
      return 'Administrador'
    }
    return 'Usuario'
  }

  get isAdmin () {
    return this.user.roles.indexOf('admin') >= 0 || this.user.roles.indexOf('superAdmin') >= 0
  }

  get adminLink () {
    if (window.mobileApp || !this.environment) return ''
    if (process.env.VUE_APP_ADMIN_DOMAIN) {
      return `https://${process.env.VUE_APP_ADMIN_DOMAIN}/${this.environment._id}`
    }
  }

  get legacyAdminLink () {
    if (window.mobileApp || !this.environment) return ''
    if (process.env.VUE_APP_ADMIN_DOMAIN_V1) {
      return `https://${process.env.VUE_APP_ADMIN_DOMAIN_V1}/${this.environment._id}`
    }
  }

  updateTitle () {
    if (this.title) {
      document.title = `${this.title} | ${this.environment.name}`
    } else {
      document.title = this.environment.name
    }
  }
}
