



























































































































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import Navbar from './Navbar.vue'
import { Environment, EnvironmentUser, User, UserProfile } from '@/models'
import _cloneDeep from 'lodash/cloneDeep'
import _pick from 'lodash/pick'
import _mapKeys from 'lodash/mapKeys'
import Form from '@/components/form/Form.vue'
import Loading from '@/components/Loading.vue'
import gql from 'graphql-tag'
import { ApolloError } from 'apollo-client'

@Component({
  components: {
    Form,
    Navbar,
    Loading
  }
})
export default class EnvironmentUserProfile extends Vue {
  @Prop({ type: Object, required: true }) environment !: Environment
  @Prop({ type: Object, required: true }) environmentUser !: EnvironmentUser
  @Prop({ type: Boolean, default: false }) sidebar !: boolean
  @Prop({ type: Boolean, default: false }) sidebarOpen !: boolean
  @Prop({ type: [Number, String] }) notificationCount ?: number | string

  tab = 'profile'
  profile : UserProfile | undefined | null = null
  profileSchema = {
    avatar: {
      label: 'Imagen de Perfil',
      type: 'file',
      optional: true,
      fieldOptions: {
        fileType: 'image',
        placeholder: '[Por defecto / Gravatar]'
      }
    },
    firstName: {
      label: 'Nombre',
      type: 'string',
      optional: true
    },
    lastName: {
      label: 'Apellido',
      type: 'string',
      optional: true
    }
  }
  profileValidationErrors : Record<string, string | boolean> = {}
  updatingProfile = false

  passwordValidationErrors : Record<string, string | boolean> = {}
  updatingPassword = false

  generating2fa = false
  twoFactorSecret = ''
  twoFactorQR = ''
  twoFactorConfirmationCode = ''


  mounted () {
    this.profile = _cloneDeep(this.user.profile)
  }

  get user () : User {
    return this.$store.state.auth.user
  }

  get envProfile () {
    return this.environmentUser.profile
  }

  get userAvatar () : string {
    return this.$store.getters['auth/userAvatarURL']
  }

  get twoFactorQRb64 () {
    if (!this.twoFactorQR) return ''
    return 'data:image/svg+xml;base64,' + btoa(this.twoFactorQR)
  }

  async updateProfile (profileData : UserProfile) {
    if (this.updatingProfile) return
    this.updatingProfile = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`mutation updateProfile ($userId : ID, $profile : UserProfileInput) {
          user: setUserProfile (userId: $userId, profile: $profile) {
            profile {
              name
              avatar {
                _id
                name
                type
                key
                bucket
                url
              }
              firstName
              lastName
            }
          }
        }`,
        variables: {
          userId: this.user._id,
          profile: _pick(profileData, Object.keys(this.profileSchema))
        }
      })
      const newProfile = data.user.profile
      this.profile = _cloneDeep(newProfile)
      this.$store.commit('auth/setUserData', { ...this.user, profile: newProfile })
      await this.onSuccess('Perfil actualizado exitosamente!')
    } catch (e) {
      await this.onError(e, 'profileValidationErrors')
    } finally {
      this.updatingProfile = false
    }
  }

  async updatePassword (data : { oldPassword : string, newPassword : string, confirm : string }) {
    if (this.updatingPassword) return
    // Check confirmation
    if (data.newPassword !== data.confirm) {
      this.$set(this, 'passwordValidationErrors', { confirm: 'Las contraseñas no coinciden' })
      return
    }
    this.updatingPassword = true
    this.$set(this, 'passwordValidationErrors', {})
    try {
      const { data: result } = await this.$apollo.mutate({
        mutation: gql`mutation changePassword($oldPassword: String, $newPassword: String) {
            result: changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
          }`,
        variables: {
          oldPassword: data.oldPassword,
          newPassword: data.newPassword
        }
      })
      if (result) {
        await this.onSuccess('Contraseña cambiada exitosamente!')
      } else {
        throw new Error('No se pudo cambiar la contraseña.')
      }
    } catch (e) {
      await this.onError(e, 'passwordValidationErrors')
    } finally {
      this.updatingPassword = false
    }
  }

  async generate2faSecret () {
    if (this.generating2fa) return
    this.generating2fa = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`mutation generateTwoFactorSecret {
          result: generateTwoFactorSecret {
            base32
            qrCode
          }
        }`
      })
      const { base32, qrCode } = data.result
      this.twoFactorQR = qrCode
      this.twoFactorSecret = base32
    } catch (e) {
      console.error(e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error',
        timeout: 10000
      })
    } finally {
      this.generating2fa = false
    }
  }

  async enable2fa () {
    if (this.generating2fa) return
    this.generating2fa = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`mutation activateTwoFactor($code : String) {
          result: activateTwoFactor(code: $code) {
            _id
            hasTwoFactor
          }
        }`,
        variables: {
          code: this.twoFactorConfirmationCode
        }
      })
      const { hasTwoFactor } = data.result
      this.twoFactorQR = ''
      this.twoFactorSecret = ''
      this.twoFactorConfirmationCode = ''
      this.$store.commit('auth/setUserData', { ...this.user, hasTwoFactor })
      await this.onSuccess('Autenticación de 2 factores habilitada correctamente.')
    } catch (e) {
      console.error(e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error',
        timeout: 10000
      })
    } finally {
      this.generating2fa = false
    }
  }

  async disable2fa () {
    if (this.generating2fa) return
    this.generating2fa = true
    try {
      const { data } = await this.$apollo.mutate({
        mutation: gql`mutation disableTwoFactor {
          result: disableTwoFactor {
            _id
            hasTwoFactor
          }
        }`
      })
      const { hasTwoFactor } = data.result
      this.$store.commit('auth/setUserData', { ...this.user, hasTwoFactor })
      await this.onSuccess('Autenticación de 2 factores deshabilitada correctamente.')
    } catch (e) {
      console.error(e)
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + e.message,
        color: 'error',
        timeout: 10000
      })
    } finally {
      this.generating2fa = false
    }
  }

  onSuccess (text : string) {
    return this.$store.dispatch('snackbar/showSnackbar', { text })
  }

  async onError (e : ApolloError, validationErrorsKey : string) {
    console.error(e)
    let message = e.message
    if (e.graphQLErrors) {
      e.graphQLErrors.forEach((err : any) => {
        if (err.error === 'validationError') {
          // this.$set(this, validationErrorsKey, err.validationErrors)
          this.$set(this, validationErrorsKey, _mapKeys(err.validationErrors, (v, k) => k.replace(/^data\./, '')))
          return
        }
      })
      message = e.graphQLErrors.map(e => e.message).join(', ')
      await this.$store.dispatch('snackbar/showSnackbar', {
        text: 'Error: ' + message,
        color: 'error',
        timeout: 10000
      })
    }
  }
}
